import API from "../utils/api";

export const ACTION_TYPES = {
    BLOG_CREATE: 'BLOG_CREATE',
    BLOG_UPDATE: 'BLOG_UPDATE',
    BLOG_DELETE: 'BLOG_DELETE',
    BLOG_FETCH: 'BLOG_FETCH',
    BLOG_FETCH_ALL: 'BLOG_FETCH_ALL',
    BLOG_PAGINATION: 'BLOG_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.blog().fetchAll()
        .then(res => {
    
            dispatch({
                type: ACTION_TYPES.BLOG_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, name = "") => dispatch => {
    API.blog().fetchPagination(page, Math.abs(limit), name)
        .then(res =>{
           console.log('1212',res.data)
            dispatch({
                type: ACTION_TYPES.BLOG_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.blog().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BLOG_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.blog().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BLOG_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.blog().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BLOG_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.blog().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.BLOG_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}