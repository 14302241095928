import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import { CheckBox } from "@material-ui/icons";
import RichTextEditor from 'react-rte';
import { Grid, IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  title: "",
  description: '',
  image: "https://picsum.photos/200",
  gallary: [],
  doctor: '',
  qna: [],
  icon: '',
  categoryId: '',
  services: [],
  videourl:""
}

const FormDialogEditResult = (props) => {

  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [question, setQuestion] = useState({ en: '', ar: '' })
  const [answer, setAnswer] = useState({ en: '', ar: '' })
  const [selectedLang, setSelectedLang] = useState('en')
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString(props.dataResult[2][selectedLang], 'html'))
  const [selectedQna, setSelectedQna] = useState(null)
 

  const handleClickOpen = () => {
    setErrors({});
    setResult({
      id: props.dataResult[0],
      title: props.dataResult[1],
      description: props.dataResult[2],
      image: props.dataResult[3],
      gallary: props.dataResult[4] ? props.dataResult[4] : [],
      doctor: props.dataResult[5]?._id,
      qna: props.dataResult[6],
      icon: props.dataResult[7],
      categoryId: props.dataResult[8]?._id,
      services: props.dataResult[9]?props.dataResult[9]:[],
      videourl:props.dataResult[10]?props.dataResult[10]:'',

    })

    setOpen(true);
  }
  const handleInputChangeForVideo = (url) => {
    setResult({ ...result, videourl: url })
   
 
  };
  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setResult({
        ...result,
        [name]: { ...result[name], [selectedLang]: value }
      });

    } else {
      setResult({ ...result, [name]: value })
    }
  }

  useEffect(() => {

    if (result.description.ar) {
      if (selectedLang === 'ar') {
        setHtmlDesc(RichTextEditor.createValueFromString(result.description.ar, 'html'))
      } else {
        setHtmlDesc(RichTextEditor.createValueFromString(result.description.en, 'html'))
      }
    }
  }, [selectedLang]);

  const handleHtmlInputChange = value => {

    setHtmlDesc(value)

    setResult({
      ...result,
      ['description']: { ...result['description'], [selectedLang]: value.toString('html') }
    });
  }


  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setResult({ ...result, icon: res.data.data.path })
      })

  }



  const handleInputChangeForGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setResult({ ...result, gallary: [...result.gallary, res.data.data.path] })

        console.log(result)
      })

  }
  const deleteFromGallary = (i) => {

    let gal = result.gallary

    gal.splice(i, 1)

    setResult({ ...result, gallary: gal })

    console.log(result)

  }

  const handleChangeForThumbnail = (img) => {

    console.log()

    setResult({ ...result, image: img })

  };

  const handleChangeForDoctor = (event) => {
    setResult({ ...result, doctor: event.target.value })

  };
  const handleChange = (event) => {
    setResult({ ...result, categoryId: event.target.value })

  };
  const handleChangeServices = (event) => {
    setResult({ ...result, services: event.target.value })

  };
  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    // if (!result.title || result.title.trim() === "") {
    //   formIsValid = false;
    //   tempErrors["title"] = "Cannot be empty";
    // }

    // if (!result.description) {
    //   formIsValid = false;
    //   tempErrors["description"] = "Cannot be empty";
    // }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(result.id, result, onSuccess)
    }
  }

  const addQna = () => {
    debugger
    if (question && answer) {
      let obj = {
        question: question,
        answer: answer
      }

      if (selectedQna != null && isFinite(selectedQna)) {

        let qna = result?.qna
        qna[selectedQna] = obj
        setResult({ ...result, qna: qna })
        setSelectedQna(null)
      } else {

        setResult({ ...result, qna: [...result?.qna, obj] })
      }
      setQuestion({ en: '', ar: '' })
      setAnswer({ en: '', ar: '' })

    }

  }

  const deleteFromQna = (i) => {

    let qna = result?.qna

    qna.splice(i, 1)

    setResult({ ...result, qna: qna })

    console.log(result)

  }
  const editFromQna = (i) => {
    setSelectedQna(i)
    let qna = result?.qna

    console.log(qna[i])
    setQuestion(qna[i].question)
    setAnswer(qna[i].answer)
    document.getElementById('qna').scrollIntoView()
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog


        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"

      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit result</DialogTitle>
        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <Button variant="contained" component="label">
            Upload Icon
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForFileType}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
          <img src={process.env.REACT_APP_IMG_URL + result.icon} height="100" style={{ marginTop: "-30px", marginLeft: '20px' }} />
          <br /><br />

          <Button variant="contained" component="label">
            Upload Picture in Gallary
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForGallary}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
          <br /><br />
          <div className="row">
            <div className="col-lg-10">
              <TextField

                name="url"
                label="Video Url"
                value={result.videourl}
                fullWidth
                onChange={(e) => handleInputChangeForVideo(e.target.value)}
                placeholder="eg. https://www.youtube.com/embed/n7-Wo5zAcps"
                {...(errors.url && { error: true, helperText: errors.url })}
              />
              <small>eg. https://www.youtube.com/embed/n7-Wo5zAcps</small>
            </div>
         

         {result.videourl?.length>0 && <div className="col-lg-2 my-3">
              <iframe className="w-100"
                src={result.videourl}>
              </iframe>
          
            </div>}

          </div>

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {result.gallary.map((img, i) => (
              <Grid item xs={2} sm={3} md={3} key={i}>

                <img key={i} src={process.env.REACT_APP_IMG_URL + img} height="140" style={{ marginRight: "10px" }} />
                <FormControlLabel control={
                  <Checkbox
                    checked={result.image === img}
                    onChange={() => handleChangeForThumbnail(img)}
                  />
                } label="Set Thumb" />

                <a onClick={() => deleteFromGallary(i)}>
                  <DeleteIcon />
                </a>

              </Grid>

            ))}
          </Grid>


          <div className="row border p-2 mb-4 mt-3">

            <div className="col-lg-12 col-12 mb-4">
              <FormControl className="float-end">
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="title"
                label="Title"
                value={result?.title[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.title && { error: true, helperText: errors.title })}
              />
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <p>Description</p>
              <RichTextEditor
                value={htmlDesc}
                onChange={handleHtmlInputChange}
              />

            </div>
          </div>



          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={result.doctor}
              onChange={handleChangeForDoctor}

            >
              {props.doctors.filter((e) => e.role === 'doctor').map(item => (
                <MenuItem value={item.id}>{item.name?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={result.categoryId}
              onChange={handleChange}

            >
              {props.categories.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Treatments</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={result.services}
              onChange={handleChangeServices}
                multiple
            >
              {props.services.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />

          <div className="card mt-4" id="qna">
            <div className="card-body">
              <strong>QNA Section</strong>
              <TextField

                name="question"
                label="Question en"
                value={question.en}
                fullWidth

                onChange={(e) => setQuestion({ ...question, en: e.target.value })}
                multiline={true}
                rows={1}

              />
              <TextField

                name="question"
                label="Question Ar"
                value={question.ar}
                fullWidth

                onChange={(e) => setQuestion({ ...question, ar: e.target.value })}
                multiline={true}
                rows={1}

              />


              <br /><br />
              <TextField

                name="answer"
                label="Answer En"
                value={answer.en}
                fullWidth
                onChange={(e) => setAnswer({ ...answer, en: e.target.value })}
                multiline={true}
                rows={1}

              />
              <TextField

                name="answer"
                label="Answer Ar"
                value={answer.ar}
                fullWidth
                onChange={(e) => setAnswer({ ...answer, ar: e.target.value })}
                multiline={true}
                rows={1}

              />


              <br /><br />


              <button className="btn btn-secondary float-end" onClick={addQna}>
                Save QNA
              </button>
            </div>
          </div>



          <br /><br />

          {result?.qna?.length > 0 && <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

            <>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Answer</th>
                    <th scope="col">#</th>
                  </tr>
                </thead>
                <tbody>
                  {result?.qna?.map((data, i) => (
                    <tr>
                      <th>{data.question[selectedLang]}</th>
                      <td>{data.answer[selectedLang]}</td>
                      <td>
                        <a onClick={() => editFromQna(i)}>
                          <EditIcon />
                        </a>
                      </td>
                      <td>

                        <a onClick={() => deleteFromQna(i)}>
                          <DeleteIcon />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </>

          </Grid>}


        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditResult;