import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "./../../../utils/api";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Paper, Grid, withStyles } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  title: "",
  type: "package",
  price: 0,
  loyalty_points: "",
  doctorId: [],
  categoryId: "",
  promo_code: "",
  description: "",
  branch: "",
  unit: "",
  status: "",
  vat: "",
  featured: false,
  gallary: [],

}

const FormDialogEditService = (props) => {
  const [open, setOpen] = useState(false);
  const [service, setService] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [currentStep, setcurrentStep] = useState('1');
  const [packageServices, setPackageServices] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {
    setPackageServices(props.dataService[16])
    setChecked(props.dataService[17])
    setcurrentStep('1')
    setErrors({});
    setService({
      id: props.dataService[0],
      image: props.dataService[1],
      title: props.dataService[2],
      type: props.dataService[3],
      price: props.dataService[4],
      loyalty_points: props.dataService[5],
      doctorId: props.dataService[6],
      categoryId: props.dataService[7],
      promo_code: props.dataService[8],
      description: props.dataService[9],
      branch: props.dataService[10],
      unit: props.dataService[11],
      status: props.dataService[12],
      vat: props.dataService[13],
      featured: props.dataService[14],
      discount: props.dataService[15],
      packageServices: props.dataService[16],
      enabledGlobalDiscount: props.dataService[17],
      gallary: props.dataService[18],
    })

    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setService({
        ...service,
        [name]: { ...service[name], [lang]: value }
      });

    } else {
      setService({ ...service, [name]: value })
    }
  }

  const handleChangeForstatus = (event) => {
    setService({ ...service, status: event.target.value })

  };

  const handleChangeForFeatured = (event) => {

    setService({ ...service, featured: event.target.checked })

  };
  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());

    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, image:  res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!service.image || service.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
    }

    // if (!service.title || service.title.trim() === "") {
    //   formIsValid = false;
    //   tempErrors["title"] = "Cannot be empty";
    // }

    if (!service.price) {
      formIsValid = false;
      tempErrors["price"] = "Cannot be empty";
    }

    if (!service.categoryId || service.categoryId.trim() === "") {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
    }

    if (!service.doctorId) {
      formIsValid = false;
      tempErrors["doctorId"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleChange = (event) => {
    setService({ ...service, categoryId: event.target.value })

  };

  const handleChangeForServices = (event) => {
    setService({ ...service, doctorId: event.target.value })

  };
  const handleBranchChangeForServices = (event) => {
    setService({ ...service, branch: event.target.value })
    setPackageServices([])

  };
  const handleUnitChangeForServices = (event) => {
    setService({ ...service, unit: event.target.value })

  };
  const handleChangeForVat = (event) => {
    setService({ ...service, vat: event.target.value })

  };
  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(service.id, service, onSuccess)
    }
  }

  const addservice = () => {
    let serv = { serviceID: '', price: '', discountedPrice: '', discount: '' }
    setPackageServices([...packageServices, serv])
    setService({ ...service, packageServices: packageServices })

  };
  const editService = (event, index) => {


    let obj = packageServices
    let val = event.target.value
    let priceArr
    let servicObj = props.servicesonly.filter((e) => e.id === val)[0]

    switch (event.target.name) {
      case "serviceID":
        obj[index].serviceID = val
        obj[index].price = servicObj.price
        obj[index].discount = servicObj.discount
        obj[index].discountedPrice = servicObj.price - servicObj.price * (servicObj.discount / 100)
        
        break;
      case "price":
        // obj[index].price = val
        break;
      case "discount":
        obj[index].discount = val
        obj[index].discountedPrice = obj[index].price - obj[index].price * (val / 100)
        break;

    }
    priceArr = obj.map( (item) => item.discountedPrice)
    console.log('112', eval(priceArr.join('+')))
    setPackageServices(obj)
    setService({ ...service, packageServices: packageServices, price: eval(priceArr.join('+')) })
  };

  const deleteService = (index) => {

    let pack = packageServices
    pack.splice(index, 1)
    setPackageServices(pack)
    setService({ ...service, packageServices: packageServices })

  };
  const handleCheckbox = (event) => {
    console.log(event.target.checked)
    setChecked(event.target.checked);

    let obj = packageServices
    let priceArr
    if(event.target.checked){
    priceArr = obj.map( (item) => item.price)
    
    }else{
      priceArr = obj.map( (item) => item.discountedPrice)
    }

    setService({ ...service, price: eval(priceArr.join('+')),enabledGlobalDiscount:event.target.checked })
    
  };

  const handleInputChangeForGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, gallary: [...service.gallary,  res.data.data.path] })

        console.log(service)
      })

  }
  const deleteFromGallary = (i) => {

    let gal = service.gallary

    gal.splice(i, 1)

    setService({ ...service, gallary: gal })

    console.log(service)

  }

  const handleChangeForThumbnail = (img) => {

    setService({ ...service, image: img })

  };
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"


      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit service</DialogTitle>

        <div>
          <IconButton color="primary" onClick={handleClickOpen} >
            <AddCircleIcon style={{ fontSize: "40px" }} />
          </IconButton>
          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="form-dialog-title"
       fullWidth
            maxWidth={'lg'}
          >
            <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit Package</DialogTitle>

            <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
              <div style={{ display: currentStep === '1' ? 'block' : 'none' }}>

                <div className="row">
                <div className="col-lg-3 col-12 mb-4">
              {/* <div className="mb-1">Gallary</div>
              <TextField
                autoFocus
                type="file"
                name="file"
                className="border"
                onChange={handleInputChangeForGallary}
                {...(errors.file && { error: true, helperText: errors.file })}
              /> */}
               <Button variant="contained" component="label">
            Upload Picture in Gallary
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForGallary}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
              </div>
              <div className="col-lg-9 col-12">
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {service?.gallary?.map((img, i) => (
                  <Grid item xs={2} sm={2} md={2} key={i}>

                    <img key={i} src={process.env.REACT_APP_IMG_URL+img} height="100" style={{ marginRight: "10px" }} />
                    <FormControlLabel control={
                      <Checkbox
                        checked={service.image === img}
                        onChange={() => handleChangeForThumbnail(img)}
                      />
                    } label="Set Thumb" />

                    <a onClick={() => deleteFromGallary(i)}>
                      <DeleteIcon />
                    </a>

                  </Grid>
                ))}
              </Grid>
              </div>
                </div>
                {/* <TextField
                  autoFocus
                  type="file"
                  name="file"
                  label="Image"
                  variant="outlined"

                  onChange={handleInputChangeForFileType}
                  {...(errors.file && { error: true, helperText: errors.file })}
                />

                <img src={process.env.REACT_APP_IMG_URL+service.image} height="100" style={{ float: "right", marginTop: "-30px" }} /> */}
<div className="row border p-2">

              <div className="col-lg-12 col-12 mb-4">
      <FormControl className="float-end">
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          value={selectedLang}
          onChange={(e) => setSelectedLang(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
        </Select>
      </FormControl>
    </div>
    <div className="col-lg-12 col-12 mb-4">
      <TextField

        name="title"
        label="Title"
        value={service?.title[selectedLang]}
        inputProps={{ 'lang': selectedLang }}
        fullWidth
        onChange={handleInputChange}
      // {...(errors.title && { error: true, helperText: errors.title })}
      />


    </div>
    <div className="col-lg-12 col-12 mb-4">
     
      <TextField

        name="description"
        label="Description"
        value={service?.description[selectedLang]}
        inputProps={{ 'lang': selectedLang }}
        fullWidth
        onChange={handleInputChange}
        multiline={true}
        rows={3}
        {...(errors.description && { error: true, helperText: errors.description })}
      />

      

    </div>
              </div>
                <br /><br />


                <TextField

                  name="loyalty_points"
                  label="Loyalty Points"
                  type="number"
                  value={service.loyalty_points}
                  fullWidth
                  onChange={handleInputChange}
                  multiline={true}
                  rows={2}
                  {...(errors.loyalty_points && { error: true, helperText: errors.loyalty_points })}
                />
                <br /><br />

                <FormControl
                  fullWidth={true}
                >
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={service.categoryId}
                    onChange={handleChange}

                  >
                    {props.categories.map(item => (
                      <MenuItem value={item.id}>{item.title?.en}</MenuItem>
                    ))}

                  </Select>
                </FormControl>

                <br /><br />

                <FormControl
                  fullWidth={true}
                >
                  <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={service.doctorId}
                    onChange={handleChangeForServices}
                    multiple
                  >
                    {props.doctors.filter((e) => e.role === 'doctor').map(item => (
                      <MenuItem value={item.id}>{item.name?.en}</MenuItem>
                    ))}

                  </Select>
                </FormControl>

                <br /><br />

                <FormControl
                  fullWidth={true}
                >
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={service.status}
                    onChange={handleChangeForstatus}

                  >

                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>


                  </Select>
                </FormControl>

                <br /><br />

                <FormControl
                  fullWidth={true}
                >
                  <InputLabel id="demo-simple-select-label">Is VAT Enabled?</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={service.vat}
                    onChange={handleChangeForVat}

                  >

                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>


                  </Select>
                </FormControl>

                <br /><br />



                <FormControl
                  fullWidth={true}
                >
                  <InputLabel id="demo-simple-select-label">Choose Unit</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={service.unit}
                    onChange={handleUnitChangeForServices}

                  >
                    {props.units.map(item => (
                      <MenuItem value={item.id}>{item.title}</MenuItem>
                    ))}

                  </Select>
                </FormControl>



                <br /><br />

                <FormControlLabel control={
                  <Checkbox
                    checked={service.featured}
                    onChange={handleChangeForFeatured}
                    inputProps={{ 'aria-label': 'controlled' }}

                  />
                } label="Featured" />
                <br /><br />
              </div>
              <div style={{ display: currentStep === '2' ? 'block' : 'none' }}>

                <FormControl
                  fullWidth={true}
                >
                  <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={service.branch}
                    onChange={handleBranchChangeForServices}

                  >
                    {props.branches.map(item => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}

                  </Select>
                </FormControl>


                <br /><br />
                {packageServices?.length>0 && packageServices.map((item, index) => (

                  <Grid container spacing={4} >

                    <Grid item xs={4} >

                      <FormControl
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-label">Service</InputLabel>
                        <Select
                          name="serviceID"
                          value={item.serviceID}
                          onChange={(event) => editService(event, index)}
                        >
                          {/* .filter(e=> !packageServices.map( (item) => item.serviceID).includes(e.id)) */}
                          {props.servicesonly.filter((e) => e.branch === service.branch).map(item => (
                            <MenuItem value={item.id} disabled={packageServices.map((item) => item.serviceID).includes(item.id)} val={item}>{item.title?.en}</MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                    <InputLabel style={{fontSize:12, marginBottom:5}}>Price</InputLabel>
                    <span style={{fontSize:16}}>{item.price}</span>
                    
                    </Grid>
                    <Grid item xs={2}>
                    <InputLabel style={{fontSize:12, marginBottom:5}}>Discounted Price</InputLabel>
                   <span style={{fontSize:16,textDecorationLine: checked?'line-through':'none'}}>  {item.discountedPrice}</span>
                    
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        type='number'
                        name="discount"
                        label="Discount in %"
                        value={item.discount}
                        fullWidth
                        onChange={(event) => editService(event, index)}
                        {...(errors.title && { error: true, helperText: errors.title })}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton style={{ color: 'red' }} onClick={() => deleteService(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>

                  </Grid>

                ))}
                <br></br>
                {service.branch && <Button variant="outlined" onClick={addservice} color="default" >
                  Add Service
                </Button>}


               {packageServices?.length>0 && service.branch &&  <Grid container spacing={4}>
                  <Grid item xs={5} ></Grid>
                 {checked? <Grid item xs={3} >
                  
                     <InputLabel style={{fontSize:12, marginBottom:5}}>Final Package Price</InputLabel>
                    <span style={{textDecorationLine: 'line-through', fontSize:14, color:'red'}}>{service.price}</span>
                    <span style={{fontSize:16}}>  {service.price- service.price * (service.discount / 100)}</span>
                  </Grid>:
                   <Grid item xs={3} >
                  
                   <InputLabel style={{fontSize:12, marginBottom:5}}>Price</InputLabel>
                 
                  <span style={{fontSize:16}}>{service.price}</span>
                </Grid>
                  }
                  <Grid item xs={3} >
                    <TextField
               
                      name="discount"
                      label="Global Discount in %"
                      value={service.discount}
                      type="number"
                      fullWidth
                      onChange={handleInputChange}
                      multiline={true}
                      disabled={!checked}
                      {...(errors.discount && { error: true, helperText: errors.discount })}
                    />
                  </Grid>
                  <Grid item xs={1} >
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckbox}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  </Grid>
                </Grid>}



              </div>
            </DialogContent>
            <DialogActions style={{ padding: 30 }}>
              <Button style={{ display: currentStep === '2' ? 'block' : 'none' }} variant="contained" onClick={() => setcurrentStep('1')} color="primary">
                Previous
              </Button>

              <Button variant="contained" onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button variant="contained" style={{ display: currentStep === '2' ? 'block' : 'none' }} onClick={handleSubmit} color="secondary">
                Save
              </Button>

              <Button style={{ display: currentStep === '1' ? 'block' : 'none' }} variant="contained" onClick={() => setcurrentStep('2')} color="secondary">
                Next
              </Button>
            </DialogActions>


          </Dialog>
        </div>


      </Dialog>
    </div>
  );
}

export default FormDialogEditService;