import { ACTION_TYPES } from "../actions/booking";

const initialState = {
    bookings: [],
    metaBooking: {}
}

export const booking = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.BOOKING_FETCH_ALL:
            return {
                ...state,
                bookings: [...action.payload]
            }
        case ACTION_TYPES.BOOKING_CREATE:
            return {
                ...state,
                bookings: [...state.bookings, action.payload]
            }
        case ACTION_TYPES.BOOKING_UPDATE:
            return {
                ...state,
                bookings: state.bookings.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.BOOKING_DELETE:
            return {
                ...state,
                bookings:state.bookings.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.BOOKING_PAGINATION:
      
            return {
                ...state,
                bookings: [...action.payload.bookings],
                metaBooking: action.payload.meta
            }
        default:
            return state;
    }
}