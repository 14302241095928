import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "./../../../utils/api";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid } from '@material-ui/core';
import RichTextEditor from 'react-rte';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  title: "",
  type: "product",
  price: 0,
  loyalty_points: "",
  doctorId: [],
  categoryId: "",
  promo_code: "",
  description: { en: "", ar: "" },
  branch: "",
  unit: "",
  status: "",
  vat: "",
  featured: false,
  discount: 0,
  staffId: [],
  department: [],
  gallary: [],
  purpose: "",
  result: "",
  duration: "",
  pain: "None",
  sessions: "",
  tags: "",
  disablePaymnet: false,
  seoh1: { en: "", ar: "" },
  seotitle: { en: "", ar: "" },
  seometa: { en: "", ar: "" },

  beforeafter: { en: "", ar: "" },
  prepare: { en: "", ar: "" },
  expect: { en: "", ar: "" },
  results: { en: "", ar: "" },
  insurance: { en: "", ar: "" },
  bagallary:[],
  icon: '',
}

const FormDialogEditService = (props) => {
  const [open, setOpen] = useState(false);
  const [service, setService] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [selectedLang, setSelectedLang] = useState('ar')
  // const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString(props.dataService[9][selectedLang], 'html'))
  const [htmlDesc, setHtmlDesc] = useState(props.dataService[9] ? RichTextEditor.createValueFromString(props.dataService[9][selectedLang], 'html') : RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc29, setHtmlDesc29] = useState(props.dataService[29] ? RichTextEditor.createValueFromString(props.dataService[29][selectedLang], 'html') : RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc30, setHtmlDesc30] = useState(props.dataService[30] ? RichTextEditor.createValueFromString(props.dataService[30][selectedLang], 'html') : RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc31, setHtmlDesc31] = useState(props.dataService[31] ? RichTextEditor.createValueFromString(props.dataService[31][selectedLang], 'html') : RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc32, setHtmlDesc32] = useState(props.dataService[32] ? RichTextEditor.createValueFromString(props.dataService[32][selectedLang], 'html') : RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc33, setHtmlDesc33] = useState(props.dataService[33] ? RichTextEditor.createValueFromString(props.dataService[33][selectedLang], 'html') : RichTextEditor.createValueFromString('', 'html'))



  const handleClickOpen = () => {
    setSelectedLang('en')
    setErrors({});
    setService({
      id: props.dataService[0],
      image: props.dataService[1],
      title: props.dataService[2],
      type: props.dataService[3],
      price: props.dataService[4],
      loyalty_points: props.dataService[5],
      doctorId: props.dataService[6],
      categoryId: props.dataService[7],
      promo_code: props.dataService[8],
      description: props.dataService[9],
      branch: props.dataService[10],
      unit: props.dataService[11],
      status: props.dataService[12],
      vat: props.dataService[13],
      featured: props.dataService[14],
      discount: props.dataService[15],
      staffId: props.dataService[16] ? props.dataService[16] : [],
      department: props.dataService[17] ? props.dataService[17] : [],
      gallary: props.dataService[18] ? props.dataService[18] : [],
      purpose: props.dataService[19] ? props.dataService[19] : '',
      result: props.dataService[20] ? props.dataService[20] : '',
      duration: props.dataService[21] ? props.dataService[21] : '',
      pain: props.dataService[22] ? props.dataService[22] : 'None',
      sessions: props.dataService[23] ? props.dataService[23] : '',
      tags: props.dataService[24] ? props.dataService[24] : '',
      disablePaymnet: props.dataService[25],
      seoh1: props.dataService[26] ? props.dataService[26] : { en: "", ar: "" },
      seotitle: props.dataService[27] ? props.dataService[27] : { en: "", ar: "" },
      seometa: props.dataService[28] ? props.dataService[28] : { en: "", ar: "" },

      beforeafter: props?.dataService?.[29] ? props.dataService[29] : { en: "", ar: "" },
      prepare: props?.dataService?.[30] ? props.dataService[30] : { en: "", ar: "" },
      expect: props.dataService[31] ? props.dataService[31] : { en: "", ar: "" },
      results: props.dataService[32] ? props.dataService[32] : { en: "", ar: "" },
      insurance: props.dataService[33] ? props.dataService[33] : { en: "", ar: "" },

      bagallary: props.dataService[34] ? props.dataService[34] : [],
      icon: props.dataService[35] ? props.dataService[35] : "",

    })
    if (selectedLang === 'ar') {
      setHtmlDesc(RichTextEditor.createValueFromString(service.description.ar, 'html'))
      setHtmlDesc29(RichTextEditor.createValueFromString(service.beforeafter.ar, 'html'))
      setHtmlDesc30(RichTextEditor.createValueFromString(service.prepare.ar, 'html'))
      setHtmlDesc31(RichTextEditor.createValueFromString(service.expect.ar, 'html'))
      setHtmlDesc32(RichTextEditor.createValueFromString(service.results.ar, 'html'))
      setHtmlDesc33(RichTextEditor.createValueFromString(service.insurance.ar, 'html'))
    } else {
      setHtmlDesc(RichTextEditor.createValueFromString(service.description.en, 'html'))
      setHtmlDesc29(RichTextEditor.createValueFromString(service.beforeafter.en, 'html'))
      setHtmlDesc30(RichTextEditor.createValueFromString(service.prepare.en, 'html'))
      setHtmlDesc31(RichTextEditor.createValueFromString(service.expect.en, 'html'))
      setHtmlDesc32(RichTextEditor.createValueFromString(service.results.en, 'html'))
      setHtmlDesc33(RichTextEditor.createValueFromString(service.insurance.en, 'html'))
    }
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setService({
        ...service,
        [name]: { ...service[name], [lang]: value }
      });

    } else {
      setService({ ...service, [name]: value })
    }
  }

  useEffect(() => {

    if (service.description.ar || service.description.en) {

      if (selectedLang === 'ar') {
        setHtmlDesc(RichTextEditor.createValueFromString(service.description.ar, 'html'))
        setHtmlDesc29(RichTextEditor.createValueFromString(service.beforeafter.ar, 'html'))
        setHtmlDesc30(RichTextEditor.createValueFromString(service.prepare.ar, 'html'))
        setHtmlDesc31(RichTextEditor.createValueFromString(service.expect.ar, 'html'))
        setHtmlDesc32(RichTextEditor.createValueFromString(service.results.ar, 'html'))
        setHtmlDesc33(RichTextEditor.createValueFromString(service.insurance.ar, 'html'))
      } else {
        setHtmlDesc(RichTextEditor.createValueFromString(service.description.en, 'html'))
        setHtmlDesc29(RichTextEditor.createValueFromString(service.beforeafter.en, 'html'))
        setHtmlDesc30(RichTextEditor.createValueFromString(service.prepare.en, 'html'))
        setHtmlDesc31(RichTextEditor.createValueFromString(service.expect.en, 'html'))
        setHtmlDesc32(RichTextEditor.createValueFromString(service.results.en, 'html'))
        setHtmlDesc33(RichTextEditor.createValueFromString(service.insurance.en, 'html'))
      }
    }
  }, [selectedLang]);

  const handleHtmlInputChange = value => {

    setHtmlDesc(value)
    //  setDoctor({ ...doctor, about: value.toString('html') })
    setService({
      ...service,
      ['description']: { ...service['description'], [selectedLang]: value.toString('html') }
    });
  }

  const handleHtmlInputChange29 = (value, name) => {

    setHtmlDesc29(value)

    setService({
      ...service,
      [name]: { ...service[name], [selectedLang]: value.toString('html') }
    });
  }
  const handleHtmlInputChange30 = (value, name) => {

    setHtmlDesc30(value)

    setService({
      ...service,
      [name]: { ...service[name], [selectedLang]: value.toString('html') }
    });
  }
  const handleHtmlInputChange31 = (value, name) => {

    setHtmlDesc31(value)

    setService({
      ...service,
      [name]: { ...service[name], [selectedLang]: value.toString('html') }
    });
  }
  const handleHtmlInputChange32 = (value, name) => {

    setHtmlDesc32(value)

    setService({
      ...service,
      [name]: { ...service[name], [selectedLang]: value.toString('html') }
    });
  }

  const handleHtmlInputChange33 = (value, name) => {

    setHtmlDesc33(value)

    setService({
      ...service,
      [name]: { ...service[name], [selectedLang]: value.toString('html') }
    });
  }

  const handleChangeForstatus = (event) => {
    setService({ ...service, status: event.target.value })

  };

  const handleChangeForFeatured = (event) => {

    setService({ ...service, featured: event.target.checked })

  };
  const handleChangeForDisablePaymnet = (event) => {

    setService({ ...service, disablePaymnet: event.target.checked })

  };
  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());

    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, icon: res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!service.icon || service.icon.length<1) {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
      toast.error('Icon cant be empty');
      return
    }
    if (!service.gallary || service.gallary.length<1) {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
      toast.error('Gallary cant be empty');
      return
    }

    if (!service.image || service.image.length<1) {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
      toast.error('Set thumbnail');
      return
    }

    if (!service.title || service.title.en.trim() === "") {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
      toast.error('Title cant be empty');
      return
    }
    if (!service.description || service.description.en.trim() === "") {
      formIsValid = false;
      tempErrors["description"] = "Cannot be empty";
      toast.error('Description cant be empty');
      return
    }

    // if (!service.price) {
    //   formIsValid = false;
    //   tempErrors["price"] = "Cannot be empty";
    // }

    if (!service.categoryId || service.categoryId.trim() === "") {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
      toast.error('Category cant be empty');
    }

    if (!service.doctorId) {
      formIsValid = false;
      tempErrors["doctorId"] = "Cannot be empty";
      toast.error('Doctor cant be empty');
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleChange = (event) => {
    setService({ ...service, categoryId: event.target.value })

  };

  const handleChangeForServices = (event) => {
    setService({ ...service, doctorId: event.target.value })

  };
  const handleChangeForStaff = (event) => {
    setService({ ...service, staffId: event.target.value })

  };
  const handleBranchChangeForServices = (event) => {
    setService({ ...service, branch: event.target.value })

  };
  const handleUnitChangeForServices = (event) => {
    setService({ ...service, unit: event.target.value })

  };
  const handleDepartmentChangeForServices = (event) => {
    setService({ ...service, department: event.target.value })

  };
  const handleChangeForVat = (event) => {
    setService({ ...service, vat: event.target.value })

  };
  const handleChangeForPain = (event) => {
    setService({ ...service, pain: event.target.value })

  };
  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(service.id, service, onSuccess)
    }
  }


  const handleInputChangeForGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, gallary: [...service.gallary, res.data.data.path] })

        console.log(service)
      })

  }
  const deleteFromGallary = (i) => {

    let gal = service.gallary

    gal.splice(i, 1)

    setService({ ...service, gallary: gal })

    console.log(service)

  }

  const handleInputChangeForbaGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, bagallary: [...service.bagallary, res.data.data.path] })

        console.log(service)
      })

  }
  const deleteFrombaGallary = (i) => {

    let gal = service.bagallary

    gal.splice(i, 1)

    setService({ ...service, bagallary: gal })

    console.log(service)

  }

  const handleChangeForThumbnail = (img) => {

    setService({ ...service, image: img })

  };
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"


      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit service</DialogTitle>

        <div>
          <IconButton color="primary" onClick={handleClickOpen} >
            <AddCircleIcon style={{ fontSize: "40px" }} />
          </IconButton>
          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="form-dialog-title"
            maxWidth={'lg'}
          >
            <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit service</DialogTitle>

            <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
      

      {/* <TextField
      autoFocus
      type="file"
      name="file"
      label="Image"
      variant="outlined"
      
      onChange={handleInputChangeForFileType}
      {...(errors.file && { error: true, helperText: errors.file })}
      />
      
      <img src={process.env.REACT_APP_IMG_URL+service.image} height="100" style={{ float: "right", marginTop: "-30px" }} /> */}
      
      
      <div className="row mt-2">
      <div className="col-lg-3 col-12 mb-4">
      <Button variant="contained" component="label">
                  Upload Icon
                  <input
                    type="file"
                    name="file"
                    label="Image"
                    onChange={handleInputChangeForFileType}
                    {...(errors.file && { error: true, helperText: errors.file })}
                    hidden
                  />
                </Button>
             
      </div>
        <div className="col-lg-9 col-12 mb-4">
          <Button variant="contained" component="label">
            Upload Picture in Gallary
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForGallary}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
      
        </div>
        {service?.icon?.length>0 && <div className="col-lg-3 col-12 mb-4">
        <img src={process.env.REACT_APP_IMG_URL + service.icon} height="100" style={{height:100}} />
      </div>}
        {service?.gallary?.length>0 &&  <div className="col-lg-9 col-12">
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {service?.gallary?.map((img, i) => (
              <Grid item xs={2} sm={2} md={2} key={i}>
      
                <img key={i} src={process.env.REACT_APP_IMG_URL + img} height="100" style={{ marginRight: "10px" }} />
                <FormControlLabel control={
                  <Checkbox
                    checked={service.image === img}
                    onChange={() => handleChangeForThumbnail(img)}
                  />
                } label="Set Thumb" />
      
                <a onClick={() => deleteFromGallary(i)}>
                  <DeleteIcon />
                </a>
      
              </Grid>
            ))}
          </Grid>
        </div>}
      
        <div className="col-lg-12 col-12 mb-4">
          <div className="row border p-2">
            <div className="col-lg-12 col-12 mb-4">
              <FormControl className="float-end">
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem>
                </Select>
              </FormControl>
            </div>
      
      
            <div className="col-lg-12 col-12 mb-4">
              <TextField
      
                name="title"
                label="Title"
                value={service?.title[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                required
              // {...(errors.title && { error: true, helperText: errors.title })}
              />
      
      
            </div>
            
            <div className="col-lg-12 col-12 mb-4">
      
              <p>Description *</p>
              <RichTextEditor
                value={htmlDesc}
                onChange={handleHtmlInputChange}
              />
      
      
      
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              <div className="row">
              <div className="col-lg-4 col-12 mb-4">
          <Button variant="contained" component="label">
            Upload Picture in Before & After Gallary
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForbaGallary}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
      
        </div>
      {service?.bagallary?.length>0 &&  <div className="col-lg-8 col-12">
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {service?.bagallary?.map((img, i) => (
              <Grid item xs={2} sm={2} md={2} key={i}>
      
                <img key={i} src={process.env.REACT_APP_IMG_URL + img} height="100" style={{ marginRight: "10px" }} />
                {/* <FormControlLabel control={
                  <Checkbox
                    checked={service.image === img}
                    onChange={() => handleChangeForThumbnail(img)}
                  />
                } label="Set Thumb" /> */}
      
                <a onClick={() => deleteFrombaGallary(i)}>
                  <DeleteIcon />
                </a>
      
              </Grid>
            ))}
          </Grid>
        </div>}
              </div>
            
              <p>Before & After</p>
              <RichTextEditor
                value={htmlDesc29}
                onChange={(val) => handleHtmlInputChange29(val, 'beforeafter')}
              />
            </div>
            <div className="col-lg-12 col-12 mb-4">
              
              <p>How to prepare</p>
              <RichTextEditor
                value={htmlDesc30}
                onChange={(val) => handleHtmlInputChange30(val, 'prepare')}
              />
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              
              <p>What to expect</p>
              <RichTextEditor
                value={htmlDesc31}
                onChange={(val) => handleHtmlInputChange31(val, 'expect')}
              />
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              
              <p>Results</p>
              <RichTextEditor
                value={htmlDesc32}
                onChange={(val) => handleHtmlInputChange32(val, 'results')}
              />
            </div>
            <div className="col-lg-12 col-12 mb-4">
              
              <p>Insurance & financing</p>
              <RichTextEditor
                value={htmlDesc33}
                onChange={(val) => handleHtmlInputChange33(val, 'insurance')}
              />
            </div>
      
            {/* <div className="col-lg-12 col-12 mb-4">
              <TextField
      
                name="purpose"
                label="Short description for departments page"
      
                value={service?.purpose[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                multiline={true}
                rows={2}
                {...(errors.purpose && { error: true, helperText: errors.purpose })}
              />
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="result"
                label="Result"
      
                value={service?.result[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.result && { error: true, helperText: errors.result })}
              />
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="duration"
                label="Duration"
                value={service?.duration[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.duration && { error: true, helperText: errors.duration })}
              />
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="sessions"
                label="Sessions"
      
                value={service?.sessions[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.sessions && { error: true, helperText: errors.sessions })}
              />
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="tags"
                label="Tags"
      
                value={service?.tags[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.tags && { error: true, helperText: errors.tags })}
              />
              <small>Add comma seperated tags</small>
            </div> */}
      <div className="col-lg-12 col-12 mb-4">
              <TextField
                name="sessions"
                label="Short Desciption for mega menu"
      
                value={service?.sessions[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.sessions && { error: true, helperText: errors.sessions })}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seoh1"
                label="SEO h1"
      
                value={service?.seoh1[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seoh1 && { error: true, helperText: errors.seoh1 })}
              />
      
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seotitle"
                label="SEO Title"
      
                value={service?.seotitle[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seotitle && { error: true, helperText: errors.seotitle })}
              />
      
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              <TextField
                name="seometa"
                label="SEO Meta"
      
                value={service?.seometa[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seometa && { error: true, helperText: errors.seometa })}
              />
      
            </div>
      
      
          </div>
        </div>
      
      
      
      
      
        {/* <div className="col-lg-6 col-12 mb-4">
          <TextField
      
            name="price"
            label="Price"
            value={service.price}
            type="number"
            fullWidth
            onChange={handleInputChange}
            {...(errors.price && { error: true, helperText: errors.price })}
          />
        </div>
        <div className="col-lg-6 col-12 mb-4">
          <TextField
      
            name="discount"
            label="Discount in %"
            value={service.discount}
            type="number"
            fullWidth
            onChange={handleInputChange}
            {...(errors.discount && { error: true, helperText: errors.discount })}
          />
        </div>
        <div className="col-lg-6 col-12 mb-4">
          <TextField
      
            name="loyalty_points"
            label="Loyalty Points"
            type="number"
            value={service.loyalty_points}
            fullWidth
            onChange={handleInputChange}
            {...(errors.loyalty_points && { error: true, helperText: errors.loyalty_points })}
          />
        </div> */}
      
        <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Category *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.categoryId}
              onChange={handleChange}
      required
            >
              {props.categories.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}
      
            </Select>
          </FormControl>
        </div>
      
        <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Doctor *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.doctorId}
              onChange={handleChangeForServices}
              multiple
              
            >
              {props.doctors.filter((e) => e.role === 'doctor').map(item => (
                <MenuItem value={item.id}>{item.name?.en}</MenuItem>
              ))}
      
            </Select>
          </FormControl>
        </div>
      
        {/* <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Staff</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service?.staffId}
              onChange={handleChangeForStaff}
              multiple
            >
              {props.doctors.filter((e) => e.role === 'nurse' || e.role === 'technician').map(item => (
                <MenuItem value={item.id} style={{ textTransform: 'capitalize' }}>{item.name?.en} - {item.role}</MenuItem>
              ))}
      
            </Select>
          </FormControl>
        </div> */}
      
        <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.status}
              onChange={handleChangeForstatus}
      
            >
      
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
      
      
            </Select>
          </FormControl>
        </div>
      
        {/* <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Is VAT Enabled?</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.vat}
              onChange={handleChangeForVat}
      
            >
      
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
      
      
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Unit</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.unit}
              onChange={handleUnitChangeForServices}
      
            >
              {props.units.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}
      
            </Select>
          </FormControl>
        </div> */}
      
        {/* <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.branch}
              onChange={handleBranchChangeForServices}
      
            >
              {props.branches.map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
      
            </Select>
          </FormControl>
        </div> */}
      
      
        {/* <div className="col-lg-6 col-12 mb-4">
      
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Pain</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.pain}
              onChange={handleChangeForPain}
      
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="Minimum">Minimum</MenuItem>
              <MenuItem value="Moderate">Moderate</MenuItem>
              <MenuItem value="Maximum">Maximum</MenuItem>
            </Select>
          </FormControl>
      
        </div> */}
        <div className="col-lg-6 col-12 mb-4">
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Departments *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.department}
              onChange={handleDepartmentChangeForServices}
              multiple
            >
              {props.departments.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}
      
            </Select>
          </FormControl>
        </div>
      
      
      
        <div className="col-lg-6 col-12">
          <FormControlLabel control={
            <Checkbox
              checked={service.featured}
              onChange={handleChangeForFeatured}
              inputProps={{ 'aria-label': 'controlled' }}
      
            />
          } label="Featured" />
        </div>
        {/* <div className="col-lg-6 col-12">
          <FormControlLabel control={
            <Checkbox
              checked={service.disablePaymnet}
              onChange={handleChangeForDisablePaymnet}
              inputProps={{ 'aria-label': 'controlled' }}
      
            />
          } label="Disable Payment" />
        </div> */}
      
      
      
      </div>
      
      <br /><br />
      
      
      
      </DialogContent>

            <DialogActions style={{ padding: 30 }}>
              <Button variant="contained" onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit} color="secondary">
                Save
              </Button>
            </DialogActions>

          </Dialog>
        </div>
        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditService;