import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/doctor";
import * as category  from "../../../actions/category";
import * as department  from "../../../actions/department";
import FormDialogAddDoctor from "../formDialog/FormDialogAddDoctor";
import FormDialogEditDoctor from "../formDialog/FormDialogEditDoctor";
import FormDialogDeleteDoctor from "../formDialog/FormDialogDeleteDoctor";
import * as branche  from "../../../actions/branche";

const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const DoctorTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [category, setCategory] = useState({})
    useEffect(() => {     
        props.fetchCategories();
        props.fetchPagination(1, rowsPerPage, '', ['doctor'])
        props.fetchDepartments();
        props.fetchBranches();
    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, '', ['doctor'])
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '', ['doctor'])
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, ['doctor'])
    };

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '', ['doctor'])
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '', ['doctor'])
    }
    
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
            customHeadRender: (columnMeta, handleToggleColumn) => {
                return (
                    <th key={columnMeta.index} 
                        style={{
                            paddingLeft: "31px", 
                            fontWeight:500, 
                            borderBottom: "1px solid rgba(224, 224, 224, .5)" 
                            }}
                    >
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                            {columnMeta.label}
                        </div>
                    </th>
                );
            },
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <span style={{marginLeft:15}}>
                      {value}
                    </span>
                );
            }
        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} 
                            style={{
                                paddingLeft: "31px", 
                                fontWeight:500, 
                                borderBottom: "1px solid rgba(224, 224, 224, .5)" 
                                }}
                        >
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                                {columnMeta.label}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value.en}
                        </span>
                    );
                }
            },
            
        },
      
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
                display: false,
            }
        },
       
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "education",
            label: "Education",
            options: {
                filter: false,
                sort: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value?.en}...
                        </span>
                    );
                }
            }
        },
        {
            name: "experience",
            label: "Experience",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value?.en}...
                        </span>
                    );
                }
            }
        },
        {
            name: "categoryId",
            label: "Category",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "about",
            label: "About",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value?.en?.slice(0,80)?.replace(/<[^>]+>|&nbsp;|&amp;/g, '')}...
                        </span>
                    );
                }
            }
        },
        {
            name: "departments",
            label: "Departments",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "branch",
            label: "Branch",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "educationdetails",
            label: "educationdetails",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "experiencedetails",
            label: "experiencedetails",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "focus",
            label: "focus",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "languages",
            label: "languages",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "keywords",
            label: "keywords",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "videos",
            label: "videos",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "gender",
            label: "gender",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "seoh1",
            label: "seoh1",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "seotitle",
            label: "seotitle",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "seometa",
            label: "seometa",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{paddingRight: "16px"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                <FormDialogAddDoctor component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                    categories ={ props.categories}
                                    departments ={ props.departments}
                                    branches ={ props.branches}
                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            <FormDialogEditDoctor
                                dataDoctor={tableMeta.rowData}
                                update={props.update}
                                categories ={ props.categories}
                                departments ={ props.departments}
                                branches ={ props.branches}
                            />
                            <FormDialogDeleteDoctor 
                                dataDoctor={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: false,
        rowsPerPageOptions: [25, 50, 75],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,

        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (
        <MUIDataTable className={classes.paperTable}
            data={props.doctors}
            columns={columns}
            options={options}
        />
    );
}

const mapStateToProps = state => ({
    doctors: state?.doctor?.doctors,
    meta: state?.doctor?.metaDoctor,
    categories: state.category.categories,
    departments: state.department.departments,
    branches: state.branche.branches,
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchCategories : category.fetchAll,
    fetchDepartments : department.fetchAll,
    fetchBranches : branche.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(DoctorTable));