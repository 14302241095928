import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "https://picsum.photos/200",
  title: "",
  description: "",
  price: 0,
  tag: "",
  expiry: "",
  branch: ""

}

const FormDialogAddGiftcard = (props) => {
  const [open, setOpen] = useState(false);
  const [giftcard, setGiftcard] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {
    setErrors({});
    setGiftcard(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setGiftcard({
        ...giftcard,
        [name]: { ...giftcard[name], [lang]: value }
      });

    } else {
      setGiftcard({ ...giftcard, [name]: value })
    }
  }
  const handleBranchChangeForServices = (event) => {
    setGiftcard({ ...giftcard, branch: event.target.value })

  };
  const handleInputChangeForFileType = event => {
 
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
    .then(res =>{
      setGiftcard({ ...giftcard, image: res.data.data.path })
    })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!giftcard.image || giftcard.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
    }
    if (!giftcard?.title?.en || giftcard.title?.en?.trim() === "") {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(giftcard, onSuccess)
    }
  }

  const handleChange = (event) => {
    setGiftcard({ ...giftcard, sliderLocation: event.target.value })

  };
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add giftcard</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
        
            <Button variant="contained" component="label">
            Upload Banner
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForFileType}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>

          <img src={process.env.REACT_APP_IMG_URL+giftcard.image} height="100" style={{ marginTop: "-30px", float: "right" }} />
         
          <br /><br />

          <div className="row border p-2">
  <div className="col-lg-12 col-12 mb-4">
      <FormControl className="float-end">
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          value={selectedLang}
          onChange={(e) => setSelectedLang(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
        </Select>
      </FormControl>
    </div>
    

    <div className="col-lg-12 col-12 mb-4">
      <TextField

        name="title"
        label="Title"
        value={giftcard?.title[selectedLang]}
        inputProps={{ 'lang': selectedLang }}
        fullWidth
        onChange={handleInputChange}
       {...(errors.title && { error: true, helperText: errors.title })}
      />


    </div>
    <div className="col-lg-12 col-12 mb-4">
     
      <TextField

        name="description"
        label="Description"
        value={giftcard?.description[selectedLang]}
        inputProps={{ 'lang': selectedLang }}
        fullWidth
        onChange={handleInputChange}
        multiline={true}
        rows={3}
        {...(errors.description && { error: true, helperText: errors.description })}
      />

      

    </div>
    
  </div>



         
          <br /><br />

          <TextField
            
            name="price"
            label="Price"
            value={giftcard.price}
            fullWidth
            onChange={handleInputChange}
            {...(errors.price && { error: true, helperText: errors.price })}
          />
          <br /><br />
          <TextField
            
            name="tag"
            label="Tag"
            value={giftcard.tag}
            fullWidth
            onChange={handleInputChange}
            {...(errors.tag && { error: true, helperText: errors.tag })}
          />
          <br /><br />

          <FormControl
      fullWidth={true}
    >
      <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={giftcard.branch}
        onChange={handleBranchChangeForServices}

      >
        {props.branches.map(item => (
          <MenuItem value={item.id}>{item.name}</MenuItem>
        ))}

      </Select>
    </FormControl>
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddGiftcard;