import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/booking";
import * as category  from "../../../actions/category";
import * as department  from "../../../actions/department";
import FormDialogAddBooking from "../formDialog/FormDialogAddBooking";
import FormDialogEditBooking from "../formDialog/FormDialogEditBooking";
import FormDialogDeleteBooking from "../formDialog/FormDialogDeleteBooking";
import * as branche  from "../../../actions/branche";
import moment from "moment";

const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const BookingTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [category, setCategory] = useState({})
    useEffect(() => {     
        // props.fetchCategories();
        props.fetchPagination(1, rowsPerPage, '')
        // props.fetchDepartments();
        // props.fetchBranches();
    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, '')
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '')
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText)
    };

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '')
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '')
    }
    
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },

        {
            // left side of first column is too close with the container, give more space on it
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} 
                            style={{
                                paddingLeft: "31px", 
                                fontWeight:500, 
                                borderBottom: "1px solid rgba(224, 224, 224, .5)" 
                                }}
                        >
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                                {columnMeta.label}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value}
                        </span>
                    );
                }
            },
            
        },
        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: false,
                sort: false,
               
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
          
            }
        },
        {
            name: "servicename",
            label: "Speciality/Doc",
            options: {
                filter: false,
                sort: false,
          
            }
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: false,
                sort: false,
              
            }
        },
        {
            name: "message",
            label: "message",
            options: {
                filter: false,
                sort: false,
              
            }
        },
        {
            name: "departments",
            label: "Departments",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "branch",
            label: "Branch",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "createdOn",
            label: "Created on",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    return moment(value).format('DD/MM/YY HH:MM a');
                },
                display: false

            }
        },
        {
            name: "datetime",
            label: "Appointment",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    return moment(value).format('DD/MM/YY HH:MM a');
                }
            }
        },
        {
            name: "source",
            label: "Platform",
            options: {
                filter: true,
                sort: false
               
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{paddingRight: "16px"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                {/* <FormDialogAddBooking component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                    categories ={ props.categories}
                                    departments ={ props.departments}
                                    branches ={ props.branches}
                                /> */}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            {/* <FormDialogEditBooking
                                dataBooking={tableMeta.rowData}
                                update={props.update}
                                categories ={ props.categories}
                                departments ={ props.departments}
                                branches ={ props.branches}
                            /> */}
                            <FormDialogDeleteBooking 
                                dataBooking={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: false,
        rowsPerPageOptions: [25, 50, 75],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: true,
        downloadOptions:{filename: 'BookingsRMC.csv', separator: ',', filterOptions:{
            useDisplayedColumnsOnly:  true,
            useDisplayedRowsOnly: true
        }},
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (
        <MUIDataTable className={classes.paperTable}
            data={props.bookings}
            columns={columns}
            options={options}
        />
    );
}

const mapStateToProps = state => ({
    bookings: state?.booking?.bookings,
    meta: state?.booking?.metaBooking,
    categories: state.category.categories,
    departments: state.department.departments,
    branches: state.branche.branches,
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchCategories : category.fetchAll,
    fetchDepartments : department.fetchAll,
    fetchBranches : branche.fetchAll,
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(BookingTable));